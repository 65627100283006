:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #191a48;
    --max-blue-green: #31c1c1;
}

.class-image {
    outline: 2px solid white;
    outline-offset: -1rem;
    margin-bottom: 2rem;
}

.class-card {
    background-color: white;
    border: 1px lightgray solid;
    padding: 1rem .5rem 1rem .5rem;
}

.line  {
    border-bottom: 1px lightgray solid;
    margin-bottom: 5px;
}

.card-title {
    font-family: dunbar-text, sans-serif;
    font-style: normal;
}

#class-link a {
    color: var(--blue)
}