:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #191a48;
    --max-blue-green: #31c1c1;
}

.robin-border {
    border: 2px solid var(--robin-blue);
    padding: 1rem 0 1rem 0; 
}