:root {
  --blue: #184f83;
  --robin-blue: #6bd8ca;
  --dark-purple: #2b2045;
  --dark-blue: #041630;
  --max-blue-green: #31c1c1;
}

.footer {
  background-color: var(--dark-blue);
}

.footer-row {
  padding-top: 80px;
  padding-bottom: 80px;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
    text-align: right;
}

.contact h2,
h6 {
  color: white;
}

.sub-form-label {
  color: white;
  font-size: 10px;
}

.form-control {
    border-radius: 0px !important;
}


.location {
  color: white;
  float: right;
}

.location-title {
  font-size: 1.05rem;
  margin: 20px;
  font-weight: bold;
}

.venue {
  color: var(--robin-blue);
  text-decoration: none;
}

.location-details {
  margin-left: 20px;
  margin-right: 20px;
}

.vert-line {
  margin-top: 30px;
  background-color: white;
  height: 105px;
  width: 2px;
}

.social {
  color: white;
  margin-top: 40px;
  font-size: 1.05rem;
  font-weight: bold;
}

.social-icon {
  color: var(--blue);
  margin: 5px 5px 5px 0;
  width: 25px;
  height: 25px;
}
  
.footer-btn {
  background-color: var(--max-blue-green);
  color: var(--dark-blue);
  border-radius: 8px !important;
  border: none;
  padding: 5px 50px 5px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: open-sans, sans-serif !important;
  font-weight: 600;
  font-style: normal;
  text-shadow: 0 3 6 var(--dark-blue);
  font-size: 14px !important;
  text-transform: uppercase !important;
  margin-top: 0rem;
}

.footer-btn:hover {
  color: var(--robin-blue);
  background-color: var(--dark-purple) !important;
}

#footer a {
  color: white;
}

.social-links:hover {
  opacity: 70%;
}

