:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #041630;
    --max-blue-green: #31c1c1;
}

.sub-block {
    color: white;
    padding-top: 60px;
    padding-bottom: 60px;
    background-size: cover;
    height: 335px
}

.sub-title {
    color: white;
}

.sub-pad {
    margin: 40px;
}

.sub {
    display: flex;
    justify-content: center;
}

.sub-form {
    display: inline-flex;
    align-items: center;
}

.form-elements {
    margin: 10px;
}

.form-input {
    width: 300px;
}
@media (max-width: 768px) {
    #subscribe button {
        float: right;
        margin-top: 1rem;
    }
    .landingSubscribe button {
        float: none !important;
        width: 100%;
        margin-top: 0;
    }
}

.landingSubscribe {
    background: linear-gradient(#2b204500, #2b2045);
    height: 100%;
}