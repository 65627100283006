:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #041630;
    --max-blue-green: #31c1c1;
    --tan: #faeccf;
}

/* body {
    background-color: var(--dark-purple) !important;
} */

.landing-bkg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.tan-bkg {
    background-color: var(--tan);
}

.landing-text {
    text-align: center;
    position: relative;
    left: 50%;
    top: 0;
    transform: translate(-50%, -8%);
    z-index: 0;
}

#landing {
    background: no-repeat url(../assets/2025/2025header-background.png);
    background-size: cover;
    background-blend-mode: multiply;
    background-color: #00000020;
}

/* @media (max-width: 575.98px) {
    .landing-text {
        padding-top: 45vh;
    }
} */

h4 {
    margin-bottom: 0px !important;
}

.landing-btn {
    padding: .8rem 2rem;
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300))) !important;
    width: 100%;
    margin-top: 3rem;
}

.landing-logo {
    width: 100%;
    height: auto;
    max-width: 200px;
}

.flip180 {
    transform: rotate(180deg);
}

.flower-image {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 15vw;
    min-width: 10vw;
}

.save-the-date {
    width: 100%;
    height: auto;
}

.dropshadow {
    filter:drop-shadow(2px 4px 6px black)
}

#rainbowBkg {
    background: no-repeat url(../assets/2024/landing/rainbow-bkg.webp);
    background-size: cover;
}

.prayer-flags-bkg {
    z-index: 0;
    position: relative;
}
.tagline-text {
    position: absolute;
    z-index: 1;
    bottom: 0;
}

#tiers {
    position: relative;
}

button {
    width: 100%;
    margin: 0rem 3rem;
}

