:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #041630;
    --max-blue-green: #31c1c1;
}

.nav-link {
    color: white !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    padding-top: 2.5rem !important;
}

#nav button {
    color: white !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    padding-top: 2.5rem !important;
    background-color: transparent !important;
    font-family: open-sans, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 16px !important;
    text-transform:none !important;
}

.nav {
    padding-top: 0px !important;
}

.navbar {
    padding-top: 0px !important;
    z-index: 5;
}


.give-back {
    background-color: var(--blue);
}

.logo {
    padding-top: 1rem;
    max-height: 60px;
    padding-left: 2rem;
}


@media (max-width: 575.98px) {
    .logo {
        visibility: hidden;
        width: 10px;
    }
}


@media (min-width: 575.99px) {
    .icon {
        visibility: hidden;
    }

}

@media (max-width: 992px) {
    .hide-lg {
        visibility: collapse;
        height: 10px !important;
    }
}

.icon {
    padding-top: 1rem;
    margin-left: 3%;
    max-height: 60px;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 2rem;
}

.offcanvas-body {
    background-color: var(--dark-purple);
}




