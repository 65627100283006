:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #041630;
    --max-blue-green: #31c1c1;
}

.hero {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.hero-text {
    text-align: center;
    position: relative;
    left: 50%;
    transform: translate(-50%, -35%);
    color: white;
    z-index: 0;
    padding-top: 40vh;

}

.hero-container {
    height: calc(70vh - 30px);
}

@media (max-width: 575.98px) {
    .hero-text {
        padding-top: 45vh;
    }
}

h4 {
    margin-bottom: 0px !important;
}

#hero button {
    background-color: var(--max-blue-green);
    color: var(--dark-blue);
    border-radius: 8px !important;
    border: none;
    padding: 5px 50px 5px 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: open-sans, sans-serif !important;
    font-weight: 600;
    font-style: normal;
    text-shadow: 0 3 6 var(--dark-blue);
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300))) !important ;
    text-transform: uppercase;
}

#hero button:hover {
    color: var(--robin-blue);
    background-color: var( --dark-blue);
}

