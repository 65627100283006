:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #191a48;
    --max-blue-green: #31c1c1;
    --bluegray: #404b57;
    --cream: #faedcf;
}

body {
    width: 100vw !important;
    height: 100vh;
    overflow-x: hidden;
}

a {
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none !important;
    color: var(--blue);
    font-display: swap;
}

a:hover {
    color: var(--max-blue-green) !important;
}

a img:hover {
    opacity: 60%;
}

p {
    font-family: open-sans, sans-serif ;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    font-display: swap;
}

h1, h2, h3 {
    font-family: dunbar-text, sans-serif;
    font-style: normal;
    font-display: swap;
}

h3 {
    font-weight: 600 !important;
    line-height: normal !important;
}

h4, h5, h6 {
    font-family: open-sans,sans-serif;
    font-weight: 700;
    font-style: normal;  
    font-display: swap;
}

h1 {
    font-size: calc(30px + (70 - 30) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: 600 !important;
}

h2 {
    font-size: calc(25px + (45 - 25) * ((100vw - 300px) / (1600 - 300))) !important;
    font-weight: 600 !important;
    letter-spacing: .05rem;
    
}

h4 {
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300))) !important;
}


.white-text {
    color: white;
}

.blue-text {
    color: var(--blue);
}

.bluegray-text {
    color: var(--bluegray)
}

#intro {
    text-align: center;
}

#intro h1, h2 {
    color: var( --blue)
}

.purple-bkg {
    background-color: var(--dark-purple);
    height: 100%;
    outline: 2px solid white;
    outline-offset: -1.5rem;
}

.blue-bkg {
    background-color: var(--blue);
    height: auto;
    width: 100vw;
    outline: 2px solid white;
    outline-offset: -1.5rem;
}

.bluegray-bkg {
    background-color: var(--bluegray);
    height: auto;
    width: 100vw;
    outline: 2px solid var(--cream);
    outline-offset: -1.5rem;
}

.cream-bkg {
    background-color: var(--cream);
    height: auto;
    width: 100vw;
    outline: 2px solid var(--bluegray);
    outline-offset: -1.5rem;
}

.dark-blue-bkg {
    background-color: var(--dark-blue);
    height: auto;
    width: 100vw;
    outline: 2px solid white;
    outline-offset: -1.5rem;
}

.rectangle-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100vh;

}

button {
    background-color: var(--max-blue-green);
    color: var(--dark-blue);
    border-radius: 8px !important;
    border: none;
    padding: 5px 2rem 5px 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: open-sans, sans-serif !important;
    font-weight: 600;
    font-style: normal;
    text-shadow: 0 3 6 var(--dark-blue);
    font-size: 14px !important;
    text-transform: uppercase !important;
}

button:hover {
    color: var(--robin-blue);
    background-color: var(--dark-blue) !important;
}

.gray-bkg {
    background-image: url(../assets/gray-bkg.webp);
    background-repeat: none;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

#img-link a:hover {
    opacity: 70%;
}

.img-outline {
    outline: 2px solid white;
    outline-offset: -1rem;
}