:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #191a48;
    --max-blue-green: #31c1c1;
}

.blue-box {
    background-color: var(--blue);
    outline: 2px solid white;
    outline-offset: -1rem;
    padding-top: 15%;
    padding-bottom: 15%;
}
.blue-box-text {
    color: white; 
    text-align: center;
    padding: .5rem;
}

.white-box {
    background-color: white;
    border: solid 1px gray;
    padding: .5rem;
    text-align: left;

}