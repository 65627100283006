:root {
    --blue: #184f83;
    --robin-blue: #6bd8ca;
    --dark-purple: #2b2045;
    --dark-blue: #041630;
    --max-blue-green: #31c1c1;
  }

.blue-outline-box {
    background-color: var(--blue);
    outline: 2px solid white;
    outline-offset: -1.5rem;
    color: white;
    text-align: center;
    padding: 0 0 2rem 0 !important;
    margin: 2rem 1rem 2rem 1rem;
}

.blue-outline-box:hover {
    opacity: 70%;
}

.outline-offset {
    background-color: white;
    outline: 2px solid var(--blue);
    outline-offset: -1.5rem;
}

.box-button-text {
    color: white;
}
